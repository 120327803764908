import React, { useState, useEffect } from "react";

import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useMap } from 'react-leaflet/hooks';

import { api_config } from "./api_url";

import { Button, Col, Row, Card} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import 'leaflet.markercluster/dist/leaflet.markercluster'

import "leaflet/dist/leaflet.css";

import L from 'leaflet';

const DisplayPreview = ({geoJSONData}) => {

  const params = new URLSearchParams(window.location.search) 
  const [hasInvalidSpatialRecord, setHasInvalidSpatialRecord] = useState();
  const [isLoading, setIsLoading] = useState(true);  
  //const api_url = api_config.url.API_URL  
  const loggedInUser = localStorage.getItem("user");
  
  delete L.Icon.Default.prototype._getIconUrl;  

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })

  useEffect(() => {    
    const isValidCoordinate = (coord) => 
      Array.isArray(coord) && coord.length === 2 &&
      !isNaN(coord[0]) && !isNaN(coord[1]);
    
    const isValidGeometry = (geometry) => {
      if (!geometry || !geometry.type || !geometry.coordinates) return false;
    
      switch (geometry.type) {
        case "Point":
          return isValidCoordinate(geometry.coordinates);
        case "LineString":
        case "MultiPoint":
          return geometry.coordinates.every(isValidCoordinate);
        case "Polygon":
        case "MultiLineString":
          return geometry.coordinates.every(ring => ring.every(isValidCoordinate));
        case "MultiPolygon":
          return geometry.coordinates.every(polygon => polygon.every(ring => ring.every(isValidCoordinate)));
        default:
          return false;
      }
    };
    const original_json_data_length = geoJSONData.data.features.length    

    if (!geoJSONData || !geoJSONData.data || !geoJSONData.data.features) {
      setHasInvalidSpatialRecord(true)
    }

    geoJSONData.data.features = geoJSONData.data.features.filter(
      (spatialRecord) => isValidGeometry(spatialRecord.geometry)
    );

    setHasInvalidSpatialRecord(geoJSONData.data.features.length < original_json_data_length)
  }, [geoJSONData])

  /*Data preview component - cluster version*/
  const DataPreview2 = () => {    
    const map = useMap();    
    if (geoJSONData.data.features.length <= 0) {
      map.setView([0, 0], 1);
      return;
    }
   
    /* Remove previous GeoJSON layer */
    map.eachLayer(function(layer){      
      if (layer instanceof L.TileLayer) {
        // Do not remove the basemap layer        
      } else {        
        map.removeLayer(layer);
      }
    });
    
    // Function to fit the map to the data bounds
    let popupContent = "<table class='spatial-preview-table'><tr><th colspan='2'>Properties</th></tr>"

    //Create cluster
    const cluster = L.markerClusterGroup({      
      spiderfyOnMaxZoom: true
    });

    //load geojson
    const dataPreview = L.geoJSON(geoJSONData.data, {      
      onEachFeature: function (feature, layer) {        
          Object.entries(feature.properties).map( (property) => {
            popupContent = popupContent + "<tr><td>" + property[0] + "</td><td>" + property[1] + "</td></tr>"
          })          
          popupContent = popupContent + "</table>"        
          layer.bindPopup(popupContent);        
          //Reset the content for another popup
          popupContent = "<table class='spatial-preview-table'><tr><th colspan='2'>Properties</th></tr>"
      }
    });

    /*cluster.on('clusterclick', (a) => {
      console.log("cluster click")  
    });*/
    
    dataPreview.addTo(cluster)    
    map.addLayer(cluster);    
    
    map.fitBounds(cluster.getBounds(),{padding: [10, 100]})
  };
  
  return (
      (!geoJSONData || (Object.keys(geoJSONData).includes("token") && geoJSONData.token === false) || (Object.keys(geoJSONData).includes("permission") && geoJSONData.permission ===false))
      ? isLoading
        ? <>
        <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
              <Spinner animation="border" role="status" >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              </div>      
        </>      
        :<>        
        <Row className='m-1 align-items-center justify-content-center text-center' >                  
        <Card style={{ width: '58rem' }}>
          <Card.Body>
            <Card.Text>              
              <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} /><br /><br />
              <span className=''> {geoJSONData.message}</span><br /><br />
              <Button className="om-button" variant='dark' href='/login' style={{ textDecoration : "none"}}>Login</Button>
            </Card.Text>
          </Card.Body>
        </Card>                  
        </Row>
        </>
      :             
      <>
        <Row>
          <Col>          
          {
            hasInvalidSpatialRecord
              ? <div className="mb-1 p-1"><span style={{ color : "red", fontStyle : "italic"}}>
                  Please be aware that there are spatial records that are invalid. As a result, certain data may not be displayed on the map.
                </span>
                </div>
              : <div className="ms-1 me-1 mb-2"></div>
          }
            
          </Col>
        </Row>
        <MapContainer className="p-1" disabled center={[51.505, -0.09]} zoom={6} zoomControl={true} scrollWheelZoom={true} style={{ height : "55vh"}} maxZoom={18}>
          <TileLayer 
            attribution='' 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
          />          
          <DataPreview2 />          
        </MapContainer>
      </>        
    );
};

export default DisplayPreview;